const general = {
  title: 'Immobilienverkauf » Focusberg Consulting',
  description:
    'Erfahren Sie jetzt, wie viel Ihre Immobilie wirklich wert ist und erhalten Sie in kurzer Zeit einen Verkaufspreis von bis zu 30 % über Marktwert.',
  author: '',
  publisher: '',
  revisitAfter: '7 days',
  type: 'website',
  image: 'share/share.png',
  site: '',
  twitterCard: 'summary_large_image',
  creator: ''
}

const data = {
  main: {
    ...general
  },
  imprint: {
    ...general,
    title: 'Impressum » Focusberg Consulting'
  },
  policy: {
    ...general,
    title: 'Datenschutz » Focusberg Consulting'
  },
  thankYou: {
    ...general,
    title: 'Danke » Focusberg Consulting'
  }
}

export default data
