import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemTitleConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '50px 0 10px',
    tablet: '50px 0 10px',
    mobile: '30px 0 10px'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',

                  config: {
                    size: 1,
                    defaultCss: {
                      textAlign: 'left'
                    },
                    text: 'Impressum'
                  }
                },
                {
                  type: 'text',

                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'FOCUSBERG CONSULTING UG (haftungsbeschränkt)<br/> Kantstrasse 38<br/> 73431 Aalen<br/> Deutschland<br/><br/> Büro Aalen<br/> WiZ Aalen | 3.OG<br/> Ulmerstraße 130<br/> 73431 Aalen<br/> Deutschland<br/><br/> Telefon: +49 (0) 7361 573 7617<br/> Email: consulting[at]focusberg.com<br/><br/> Registernummer: HRB 738998<br/> Registergericht: Amtsgericht Ulm<br/><br/> vertreten durch den Geschäftsführer: Gazi Korkmaz <br/><br/> ​ Umsatzsteuer-ID<br/> Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:<br/> DE328497524<br/><br/> ​Gewerbeerlaubnis<br/><br/> Die Gewerbeerlaubnis nach §34c GewO wurde am 13.07.2020 durch die IHK Ostwürttemberg erteilt. <br/><br/> Innerhalb der Bundesrepublik Deutschland ist sowohl eine Gewerbeerlaubnis für die Tätigkeit als Immobiliardarlehensvermittler gemäß § 34i Abs. 1 S. 1 GewO und die Tätigkeit als Vermittler von Darlehen gemäß § 34c Abs. 1 GewO vorhanden. <br/><br/> Immobiliardarlehensvermittlerregister <br/> Einsehbar unter www.vermittlerregister.info | Register Nr. D-W-135-Y8NR-10 <br/>​<br/> Aufsichtsbehörde nach § 34c GewO: <br/> IHK Ostwürttemberg, Ludwig-Erhard-Straße 1, 89520 Heidenheim<br/> Telefon: 07321 3240, www.ostwuerttemberg.ihk.de <br/><br/> Aufsichtsbehörde nach § 34iGewO: <br/> IHK Ostwürttemberg, Ludwig-Erhard-Straße 1, 89520 Heidenheim<br/> Telefon: 07321 3240, www.ostwuerttemberg.ihk.de '
                  }
                },
                {
                  type: 'text',

                  config: {
                    size: 4,
                    ...itemTitleConfig,
                    text: 'Design und technische Umsetzung'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Die grafische Gestaltung und technische Umsetzung dieser Webseite wurde von der Peakconcepts GmbH realisiert. Für den Inhalt bleibt der Herausgeber dieser Webseite verantwortlich.<br /><br />Webseiten: <a href="https://www.peakconcepts.de" rel="noreferrer" target="_blank"><u>peakconcepts.de</u></a> und <a href="https://www.leeeds.de" rel="noreferrer" target="_blank"><u>leeeds.de</u></a>'
                  }
                },
                {
                  type: 'text',

                  config: {
                    size: 4,
                    ...itemTitleConfig,
                    text: 'Bildnachweise'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'G-Stock Studio // <a target="_blank" rel="noopener noreferrer" href="https://www.shutterstock.com/de/image-photo/sealing-deal-top-view-two-men-431846332">Shutterstock</a><br/>Branislav Nenin // <a target="_blank" rel="noopener noreferrer" href="https://www.shutterstock.com/de/image-photo/portrait-couple-financial-problems-looking-document-1552483802">Shutterstock</a><br/>Dragana Gordic // <a target="_blank" rel="noopener noreferrer" href="https://www.shutterstock.com/de/image-photo/senior-couple-counting-bills-home-happy-1856465734">Shutterstock</a><br/>bbernard // <a target="_blank" rel="noopener noreferrer" href="https://www.shutterstock.com/de/image-photo/real-estate-agent-handing-over-key-<br/>LightField Studios // <a target="_blank" rel="noopener noreferrer" href="https://www.shutterstock.com/de/image-photo/young-man-sale-board-selling-his-730777636">Shutterstock</a><br/>BalanceFormCreative // <a target="_blank" rel="noopener noreferrer" href="https://www.shutterstock.com/de/image-photo/female-real-estate-agent-offer-home-642415924">Shutterstock</a><br/>'
                  }
                },
                {
                  type: 'text',

                  config: {
                    size: 4,
                    ...itemTitleConfig,
                    text: 'EU-Streitschlichtung'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.<br/>Unsere E-Mail-Adresse finden Sie oben im Impressum.'
                  }
                },
                {
                  type: 'text',

                  config: {
                    size: 4,
                    ...itemTitleConfig,
                    text:
                      'Verbraucher­streit­beilegung/Universal­schlichtungs­stelle'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.'
                  }
                },
                {
                  type: 'text',

                  config: {
                    size: 4,
                    ...itemTitleConfig,
                    text: 'Haftung für Inhalte'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.'
                  }
                },
                {
                  type: 'text',

                  config: {
                    size: 4,
                    ...itemTitleConfig,
                    text: 'Haftung für Links'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.'
                  }
                },
                {
                  type: 'text',

                  config: {
                    size: 4,
                    ...itemTitleConfig,
                    text: 'Urheberrecht'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.'
                  }
                },
                {
                  type: 'text',

                  config: {
                    size: 4,
                    ...itemTitleConfig,
                    text: 'Rechtswirksamkeit dieses Haftungsausschlusses'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in Ihrem Inhalt und Ihrer Gültigkeit davon unberührt.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
