import colors from '../colors'

const data = {
  /** Theme */
  theme: {
    primary: colors.primary
  },
  /** TagManager */
  gtmID: 'GTM-PTTRG86',
  /** Calendly */
  calendly: {
    pageSettings: {
      backgroundColor: 'ffffff',
      hideEventTypeDetails: false,
      hideLandingPageDetails: false,
      primaryColor: colors.primary,
      textColor: '000000'
    },
    url: '',
    isPage: true
  }
}

export default data
