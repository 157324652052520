const data = {
  sectionConfig: {
    padding: {
      desktop: '40px 0',
      tablet: '40px 0',
      mobile: '40px 0'
    }
  },
  company:
    'FOCUSBERG CONSULTING UG (haftungsbeschränkt)<br/> Kantstrasse 38<br/> 73431 Aalen<br/> Deutschland',
  provider: 'ALL-INKL',
  customCookieScriptTag: {
    id: 'CookieDeclaration',
    src: 'https://consent.cookiebot.com/100c888b-db58-4d4c-8b10-XXXX/cd.js',
    type: 'text/javascript',
    async: true
  }
}

export default data
