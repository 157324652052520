import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../content/default/colors'

const itemCardConfig = {
  defaultCss: {
    textAlign: 'center',
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    padding: '50px 30px 30px',
    backgroundColor: '#fff'
  },
  margin: {
    desktop: '40px 0 0',
    tablet: '40px 0 0',
    mobile: '40px 0 0'
  }
}

const itemTitleConfig = {
  size: 6,
  defaultCss: {
    textAlign: 'center'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center',
    fontStyle: 'italic'
  }
}

const itemNameConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const itemImageConfig = {
  height: {
    desktop: '100px'
  },
  defaultCss: {
    borderRadius: '50%'
  },
  margin: {
    desktop: '-90px 0 30px',
    tablet: '-90px 0 30px',
    mobile: '-90px 0 20px'
  }
}

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'vorteile'
  },
  defaultCss: {
    backgroundColor: colors.secondary
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            text: 'Das sagen unsere Kunden'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto',
              mobile: '30px auto'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/reviews/schlichter.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: 'Lorem ipsum dolor sit ame',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.”',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '⭐⭐⭐⭐⭐ <br/>Referenzname',
                        ...itemNameConfig
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/reviews/meinert.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: 'Lorem ipsum dolor sit ame',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.”',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '⭐⭐⭐⭐⭐ <br/>Referenzname',
                        ...itemNameConfig
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/reviews/mueller.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: 'Lorem ipsum dolor sit ame',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.”',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '⭐⭐⭐⭐⭐ <br/>Referenzname',
                        ...itemNameConfig
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
